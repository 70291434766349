/**
 * Shadow the chakra theme
 */
import { extendTheme } from '@chakra-ui/react';

// Overwrite the chakra component
const Button = {
  baseStyle: {
    background: 'porttoGrayBlue',
    color: 'white',
    borderRadius: '30px',
    transition: '.2s opacity, .2s transform',
    WebkitTapHighlightColor: 'transparent',
    _focus: { boxShadow: 'none' },
    _hover: {
      opacity: { base: 1, md: 0.8 },
      transform: { base: 'none', md: 'scale(0.98)' },
    },
    _active: {
      transform: 'scale(0.96)',
    },
  },
  variants: {
    base: { height: '62px', px: '60px' },
  },
  defaultProps: {
    variant: 'base',
  },
};

const Link = {
  baseStyle: {
    WebkitTapHighlightColor: 'transparent',
    _focus: { boxShadow: 'none' },
    _hover: {
      textDecoration: 'none',
      opacity: { base: 1, md: 0.8 },
      transform: { base: 'none', md: 'scale(0.98)' },
    },
    _active: {
      transform: 'scale(0.96)',
    },
    transition: '.2s opacity, .2s transform',
  },
};

const Heading = {
  baseStyle: {
    color: 'porttoBlack',
    textTransform: 'none',
  },
  variants: {
    h1: {
      fontSize: { base: '36px', md: '44px' },
      fontWeight: '700',
      lineHeight: { base: '50px', md: '60px' },
    },
    h2: {
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '34px',
    },
    h3: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '30px',
    },
    h4: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '26px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '22px',
    },
  },
  defaultProps: {
    size: '',
  },
};

// Custom components
const LayoutContainer = {
  baseStyle: {
    width: '100%',
    maxWidth: '1440px',
    minHeight: 'calc(100% - 500px)',
    px: { base: '20px', md: 'min(120px, 8.3%)' },
    margin: '0 auto',
  },
};

const HeaderCategory = {
  baseStyle: {
    margin: '0',
    padding: { base: '20px', lg: '0' },
    lineHeight: '22px',
    color: 'porttoBlack',
    textTransform: 'uppercase',
    _hover: { fontWeight: { md: '600' } },
    _active: {
      transform: 'scale(0.98)',
    },
  },
};

const HeaderListItem = {
  baseStyle: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22px',
    color: '#32323D',
  },
};

const Card = {
  baseStyle: {
    minWidth: '300px',
    minHeight: '300px',
    borderRadius: '30px',
    boxShadow: '0px 0px 20px rgba(127, 127, 127, 0.05)',
    overflow: 'hidden',
  },
};

const theme = {
  fonts: {
    heading: 'Work Sans',
    body: 'Work Sans',
  },
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
        color: 'porttoBlack',
        fontSize: '14px',
        lineHeight: '18px',
      },
      p: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        margin: 0,
      },
    },
  },
  colors: {
    porttoGrayBlue: '#4D5472',
    porttoBlack: '#141414',
    porttoGray01: '#7F7F7F',
    porttoGray03: '#F9F9F9',
  },
  components: {
    Button,
    Link,
    Heading,
    LayoutContainer,
    HeaderCategory,
    HeaderListItem,
    Card,
  },
};

export default extendTheme(theme);
