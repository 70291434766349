/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import Helmet from 'react-helmet';
import * as config from './gatsby-config';

// add canonical link for pages
// eslint-disable-next-line import/prefer-default-export
export function wrapPageElement({ element, props }) {
  const { siteUrl } = config.siteMetadata;
  const { location } = props;
  const path = location.pathname.split('/');
  const shouldIndexPaths = ['/', '/zh/terms/', '/zh/privacy/', '/zh/'];
  const shouldIndex = shouldIndexPaths.some((p) => location.pathname === p);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${siteUrl}${path[1] === 'zh' ? 'zh' : ''}`}
        />
        {path[1] && !shouldIndex ? (
          <meta name="robots" content="noindex" />
        ) : null}
      </Helmet>
      {element}
    </>
  );
}

export function shouldUpdateScroll({
  routerProps: {
    location: { hash },
  },
  pathname,
}) {
  if ((pathname === '/' || pathname === '/zh') && hash === '#news') {
    document.getElementById('news')?.scrollIntoView({ behavior: 'smooth' });
    return false;
  }

  window.scrollTo(0, 0);

  return false;
}
