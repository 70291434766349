module.exports = {
  siteMetadata: {
    author: '@BloctoApp',
    keywords:
      'blockchain, blockchain technology, crypto platform, crypto wallet, crypto asset, NFT collection, NFT marketplace, NFT trading, crypto trade, IDO platform, decentralized governance, token economy, crypto blog',
    siteUrl: 'https://portto.com/',
    image: 'https://portto.com/images/portto-social.jpg',
    square_image: 'https://portto.com/images/portto-social-square.jpg',
    fb_app_id: '508291339938435',
  },
  trailingSlash: 'never',
  plugins: [
    'gatsby-plugin-react-helmet',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/static/images`,
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#FFFFFF',
        display: 'minimal-ui',
        icon: `${__dirname}/static/images/logo-square.png`, // This path is relative to the root of the site.
      },
    },
    'gatsby-plugin-sass',
    // {
    //   resolve: 'gatsby-plugin-web-font-loader',
    //   options: {
    //     google: {
    //       families: ['Noto+Sans:400,700', 'Work+Sans:400,600'],
    //     },
    //   },
    // },
    'gatsby-plugin-preload-fonts',
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        output: ".",
        excludes: [
          '/download/',
          '/zh/download/',
          '/mobile/',
          '/privacy/',
          '/terms/',
        ],
      },
    },
    {
      resolve: "gatsby-plugin-hubspot",
      options: {
        trackingCode: "20594756",
        respectDNT: false,
        productionOnly: true,
      },
    },
    {
      resolve: 'gatsby-plugin-intl',
      options: {
        // language JSON resource path
        path: `${__dirname}/src/intl`,
        // supported language
        languages: ['en', 'zh'],
        // language file path
        defaultLanguage: 'en',
        // option to redirect to `/en` when connecting `/`
        redirect: false,
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        policy: [
          {
            userAgent: '*',
            disallow: [
              '/email-protection',
              '/download',
              '/zh/download',
              '/serum',
              '/404',
              '/mobile',
            ],
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-56WWT4J',

        // Include GTM in development.
        //
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false,

        // datalayer to be set before GTM is loaded
        // should be an object or a function that is executed in the browser
        //
        // Defaults to null
        defaultDataLayer: { platform: 'portto.com' },

        // Specify optional GTM environment details.
        // gtmAuth: 'YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_AUTH_STRING',
        // gtmPreview: 'YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_PREVIEW_NAME',
        // dataLayerName: 'portto.com',
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
    'gatsby-plugin-netlify',
    '@chakra-ui/gatsby-plugin',
  ],
};
